// 导入封装好的axios实例
import request from "./request";
import { RSA_encryption, RSA_decrypt } from "@/utils/encrypt";

function decodeUnicode(str) {
  return str.replace(/\\u[\dA-Fa-f]{4}/g, function (match) {
    return String.fromCharCode(parseInt(match.substr(2), 16));
  });
}

const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */

  get(url, params) {
    const config = {
      method: "get",
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
  async post(url, params) {
    const config = {
      method: "post",
      url: url,
    };
    console.log("参数", params);
    if (params) config.data = RSA_encryption(params);
    const response = await request(config);
    response.data = JSON.parse(decodeUnicode(RSA_decrypt(response.data)));
    // console.log("返回值",response.data)
    return response;
  },
  // post(url, params) {
  //     const config = {
  //       method: "post",
  //       url: url,
  //     };
  //     if (params) config.data = params;
  //     return request(config);
  //   },
  put(url, params) {
    const config = {
      method: "put",
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
  delete(url, params) {
    const config = {
      method: "delete",
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
};
//导出
export default http;
