// import jquery from "./jquery";
// import { JSEncrypt } from "./jsencrypt";


// var PUBLIC_KEY = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArbjF2hWol5oAvNjPLVNHBBjHOEVJzsnnPgm+8gZGFVWBOfqCNnaARb1O/YQCjRh2amqJ/7eDDAM+62fhOZH5e298CyIPUof4y1/Rr1ZSrUrVZhTVy1qj1hv6eS4OOy+uCBUHVRAmRlFGsEVDAvicwCWKdQoeUob7QeEWVHo2PDHJYa4cKLFkW5NvzgBhEwwTdgYBeqeVGbbjU0xC/ethYFKigKkFE4aTJrd6lXuVgSTNMG7+PgX8K5J1wTcUNZZWDMLUIkBTPEhlQxiFBzPRlPsQdOlfzh5kzfAaBdHsJkvd0RdTenYEXSkNtuc0ACr8f1MfrVTOh/TupD9C7HelkwIDAQAB';
// var PRIVATE_KEY = 'MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQCtuMXaFaiXmgC82M8tU0cEGMc4RUnOyec+Cb7yBkYVVYE5+oI2doBFvU79hAKNGHZqaon/t4MMAz7rZ+E5kfl7b3wLIg9Sh/jLX9GvVlKtStVmFNXLWqPWG/p5Lg47L64IFQdVECZGUUawRUMC+JzAJYp1Ch5ShvtB4RZUejY8MclhrhwosWRbk2/OAGETDBN2BgF6p5UZtuNTTEL962FgUqKAqQUThpMmt3qVe5WBJM0wbv4+BfwrknXBNxQ1llYMwtQiQFM8SGVDGIUHM9GU+xB06V/OHmTN8BoF0ewmS93RF1N6dgRdKQ225zQAKvx/Ux+tVM6H9O6kP0Lsd6WTAgMBAAECggEAVzRDjUAGC2PxLtse1L/TDElr5+FQNY/AlrHJujBQCfGMFxCbQL+nkFqBRl138l/vQWgNseHq1KLkeMMCmiWeuMmz2NrWGjXLPDvzRDLu+bJxAPdAgv4S9C3DRAVMe4VptQDpV1ft/qpZ864w/1LRTNsLb8kOg5rikaVt0iYvB3Oau42np2HcI5GQKWLRZyCS91Agf7ZwUnh6iqa7MNQADGAYu+l0qGXSPLLF2ilaejkRgYdhz7ixSYZtdo4hzr/zjkxTHZkps5ahoJ6tA64Ca/GbGlsj1ukVwAP+QcgsJ5Gm4+axRGqNy+OtuB2zdHNNzyundGvOV+kF/bei1LSA4QKBgQDYPSJGibUYd8TFHji5p9hP9ktBKrMnR7bcJImpldNEzcFO43bE4mKr33dSVWsmrcxaZ4feu8aGfp2C3ukVSSawF48PbUB2LngdkyK+ihi2WwUDI96Yp8SETuUGWva4NDfLg/HTB7TCt9p8JXfW+PTGmME7uZT2srAbQnyoLfxL2QKBgQDNqkNA6+8eO1JhdEIlb1Oe9RFksVOpCAuJeC19KakjhsEI8QS9RmVbiCQ7gqJpSdEI20qruaIzX9vSbU2ikHmHT9P3LeSGOIBChrMS9rmKHW74aliXummnJxTtHnTv76roMM8d2K4YrgbDR1vBzJBB7WgFOc4rwJsT+qwMjjW1SwKBgQC9QJQVBET5CymuE4s2Ac5x/W9Ts5lkkYFbHqdVQwnKuAGtl6rFGhWds1DtQFEtYwLtRJtHtzRDVeFpPsrklEdF6viMWeQHCxiZWcMY3ChQwUybuPMCzCJUYfBUJZy1Dw6eEfa96YqsuNiBuxEtlmo0vlCwgvX7C9zRntkXIFgl0QKBgQC/7FGSdhnQ1n8MHpfTo04fmoa+tpRhuvY5NQMOOCB03L2rAG/xyY2wmx1kw4WYR6BgaXQHSNZKpvvWJNY8DCK82QvvMM5/Sp62dkkKO1/tuYXjICrIfNc3e0sV9EQf3EJbJ6FAMwJUWaJJ/X9tHN7KByf5KHZzcevF+zkvBk6sfQKBgQCEupoGPshXTESHR568Tl5bKkk+Z4qHrs1uzDs8kCLlWKjT3CNfEW8+VKXWnxlhs0iKSSA8sWhaWt1jdOe/Fl7jXSBw6NVjFSjr7pHKm0n1gqO700tEy8uxXcoLK9DUC2pYolrU6ojUq9ZSINPgJnW1WDjOOr85HAjk16hAzuSwEA==';

// /**
//  * 加密方法
//  */
// export function RSA_encryption(jsonData) {
//     var encrypt = new JSEncrypt();
//     encrypt.setPublicKey('-----BEGIN PUBLIC KEY-----' + PUBLIC_KEY + '-----END PUBLIC KEY-----');
//     var encrypted = encrypt.encrypt(JSON.stringify(jsonData));
//     return encrypted;
// }

// /*
// 解密方法
// */
// export function RSA_decrypt(jsonData) {
//      var encrypt = new JSEncrypt();
//      encrypt.setPrivateKey(  PRIVATE_KEY  );
//      var ret = encrypt.decrypt(jsonData);
//      return JSON.parse(ret);
// }


import { JSEncrypt } from "./jsencrypt.min";
import { hex2b64, b64tohex } from './sec'

// var PUBLIC_KEY = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArbjF2hWol5oAvNjPLVNHBBjHOEVJzsnnPgm+8gZGFVWBOfqCNnaARb1O/YQCjRh2amqJ/7eDDAM+62fhOZH5e298CyIPUof4y1/Rr1ZSrUrVZhTVy1qj1hv6eS4OOy+uCBUHVRAmRlFGsEVDAvicwCWKdQoeUob7QeEWVHo2PDHJYa4cKLFkW5NvzgBhEwwTdgYBeqeVGbbjU0xC/ethYFKigKkFE4aTJrd6lXuVgSTNMG7+PgX8K5J1wTcUNZZWDMLUIkBTPEhlQxiFBzPRlPsQdOlfzh5kzfAaBdHsJkvd0RdTenYEXSkNtuc0ACr8f1MfrVTOh/TupD9C7HelkwIDAQAB';
// var PRIVATE_KEY = 'MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQCtuMXaFaiXmgC82M8tU0cEGMc4RUnOyec+Cb7yBkYVVYE5+oI2doBFvU79hAKNGHZqaon/t4MMAz7rZ+E5kfl7b3wLIg9Sh/jLX9GvVlKtStVmFNXLWqPWG/p5Lg47L64IFQdVECZGUUawRUMC+JzAJYp1Ch5ShvtB4RZUejY8MclhrhwosWRbk2/OAGETDBN2BgF6p5UZtuNTTEL962FgUqKAqQUThpMmt3qVe5WBJM0wbv4+BfwrknXBNxQ1llYMwtQiQFM8SGVDGIUHM9GU+xB06V/OHmTN8BoF0ewmS93RF1N6dgRdKQ225zQAKvx/Ux+tVM6H9O6kP0Lsd6WTAgMBAAECggEAVzRDjUAGC2PxLtse1L/TDElr5+FQNY/AlrHJujBQCfGMFxCbQL+nkFqBRl138l/vQWgNseHq1KLkeMMCmiWeuMmz2NrWGjXLPDvzRDLu+bJxAPdAgv4S9C3DRAVMe4VptQDpV1ft/qpZ864w/1LRTNsLb8kOg5rikaVt0iYvB3Oau42np2HcI5GQKWLRZyCS91Agf7ZwUnh6iqa7MNQADGAYu+l0qGXSPLLF2ilaejkRgYdhz7ixSYZtdo4hzr/zjkxTHZkps5ahoJ6tA64Ca/GbGlsj1ukVwAP+QcgsJ5Gm4+axRGqNy+OtuB2zdHNNzyundGvOV+kF/bei1LSA4QKBgQDYPSJGibUYd8TFHji5p9hP9ktBKrMnR7bcJImpldNEzcFO43bE4mKr33dSVWsmrcxaZ4feu8aGfp2C3ukVSSawF48PbUB2LngdkyK+ihi2WwUDI96Yp8SETuUGWva4NDfLg/HTB7TCt9p8JXfW+PTGmME7uZT2srAbQnyoLfxL2QKBgQDNqkNA6+8eO1JhdEIlb1Oe9RFksVOpCAuJeC19KakjhsEI8QS9RmVbiCQ7gqJpSdEI20qruaIzX9vSbU2ikHmHT9P3LeSGOIBChrMS9rmKHW74aliXummnJxTtHnTv76roMM8d2K4YrgbDR1vBzJBB7WgFOc4rwJsT+qwMjjW1SwKBgQC9QJQVBET5CymuE4s2Ac5x/W9Ts5lkkYFbHqdVQwnKuAGtl6rFGhWds1DtQFEtYwLtRJtHtzRDVeFpPsrklEdF6viMWeQHCxiZWcMY3ChQwUybuPMCzCJUYfBUJZy1Dw6eEfa96YqsuNiBuxEtlmo0vlCwgvX7C9zRntkXIFgl0QKBgQC/7FGSdhnQ1n8MHpfTo04fmoa+tpRhuvY5NQMOOCB03L2rAG/xyY2wmx1kw4WYR6BgaXQHSNZKpvvWJNY8DCK82QvvMM5/Sp62dkkKO1/tuYXjICrIfNc3e0sV9EQf3EJbJ6FAMwJUWaJJ/X9tHN7KByf5KHZzcevF+zkvBk6sfQKBgQCEupoGPshXTESHR568Tl5bKkk+Z4qHrs1uzDs8kCLlWKjT3CNfEW8+VKXWnxlhs0iKSSA8sWhaWt1jdOe/Fl7jXSBw6NVjFSjr7pHKm0n1gqO700tEy8uxXcoLK9DUC2pYolrU6ojUq9ZSINPgJnW1WDjOOr85HAjk16hAzuSwEA==';
var PUBLIC_KEY = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCXLGPbI3Rj0p+zmpcmCOfcXlw2L7TmAvny5GDsdcPZG5pr1WlAC/Hzr0OTZMV7WSZNj7IKiumHqoCw5g0+Ue5p1c0I8HPzbeZg+g7tpn1vfo9CI4wEK44Pxgo9+MBvGnE/1jTcGxQRWV/tkFtYLQSu3kOpb1qqLQ5B61TeQPOpEwIDAQAB'
var PRIVATE_KEY = 'MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBAJcsY9sjdGPSn7OalyYI59xeXDYvtOYC+fLkYOx1w9kbmmvVaUAL8fOvQ5NkxXtZJk2PsgqK6YeqgLDmDT5R7mnVzQjwc/Nt5mD6Du2mfW9+j0IjjAQrjg/GCj34wG8acT/WNNwbFBFZX+2QW1gtBK7eQ6lvWqotDkHrVN5A86kTAgMBAAECgYBBWAaxqlmc7GXrlF7RKfhO4Nqq8WQYKCEyRFVA4IaPScct5UlhTch/tLpY864I6zgLPpmOxQy8f46w6D/BOFj86/C629DGfMWx6yFspgzEPMFZ8R7Z182RIYJnP7PnM40h+qyq1mIv2ck1KfGDWZU9n+SoamBBKWclCvQmgjOJQQJBANFFbVx4hgjkiQYbNOfmgl0Ni5/wbZFFF6cxSort7Z+mASUQm1pFb9QPea08qLDOyduBhHJZCdOC+LUgCAiOJF0CQQC47erYfGHr8irL8TF7xnnzHU49sL6Fr5786KHcMJKeZYv6Y8BT9NG0zZtCj/9qoyQUKTxZP/xeGJD7kEcpVywvAkEAwg/l2EiT0w9K2fTE3P/viCcVgZ3NnVc7FfoprCfzoYaprRt3VQDrMPWg2jtUG5HHstyqW/ehmxjrVdt8V6KoqQJBAKf6pRokUaZLAQjje/lcniVxd0KeK1Gv5xEAFxa1oFj1EKNOvMMGXP6IzQE3X+Dzc7BEjKbdclXepsN7mMg6gM0CQQCBeZi8ysPJOmb9mBKM0GPLupSfSoWCmBl2O6t8LFsWrllOaVxfhIj8xwSuh1tpGKxkXa4OZUjLMh35uxkt6qSf'


//分段加密方法
JSEncrypt.prototype.encryptLong = function (string) {
    var k = this.getKey(),
        maxLength = (((k.n.bitLength() + 7) >> 3) - 11);

    try {
        var lt = "",
            ct = "";

        if (string.length > maxLength) {
            lt = string.match(/.{1,50}/g);
            lt.forEach(entry => {
                var t1 = k.encrypt(entry);
                ct += t1;
            });
            return hex2b64(ct);
        }
        var t = k.encrypt(string),
            y = hex2b64(t);
        return y;
    } catch (ex) {
        return ex;
    }
};

function splitString(s) {  
    var fragments = [];  
    var start = 0;  
    while (start < s.length) {  
      var end = Math.min(start + 256, s.length);  
      while (end > start && s.charCodeAt(end - 1) > 127) {  
        end--;  
      }  
      fragments.push(s.substring(start, end));  
      start = end;  
    }  
    return fragments;  
  }

//分段解密方法
JSEncrypt.prototype.decryptLong = function (string) {
    var k = this.getKey(),
        maxLength = (((k.n.bitLength() + 7) >> 3) - 11);

    try {
        var ct = '';
        string = b64tohex(string)
        if (string.length > maxLength) {
            var lt = splitString(string);
            lt.forEach(function (entry) {
                var t1 = k.decrypt(entry);
                ct += t1;
            });
            return ct;
        }
        var y = k.decrypt(b64tohex(string));
        return y;
    } catch (ex) {
        return ex;
    }
};

//分段加密
export function RSA_encryption(jsonData) {
    var encrypt = new JSEncrypt()
    encrypt.setPublicKey('-----BEGIN PUBLIC KEY-----' + PUBLIC_KEY + '-----END PUBLIC KEY-----');
    var data = encrypt.encryptLong(JSON.stringify(jsonData))
    return data
}

//分段解密
export function RSA_decrypt(jsonData) {
    var decryptLong = new JSEncrypt();
    decryptLong.setPrivateKey(PRIVATE_KEY);
    var data = decryptLong.decryptLong(jsonData);
    // return /^{.+}$/g.test(data) && JSON.parse(data) || data;
    return data
}